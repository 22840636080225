<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (En)"
            rules="required"
          >
            <b-form-group
              label="Name (En)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="categoryForm.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name (Ar)"
            rules="required"
          >
            <b-form-group
              label="Name (Ar)"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="categoryForm.name_ar"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Description -->

          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (En)"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >

              <b-form-textarea
                v-model="categoryForm.description"
                type="text"
                rows="1"
                placeholder="Description (En)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Description -->

          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description (Ar)"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                v-model="categoryForm.description_ar"
                type="text"
                rows="1"
                placeholder="Description (En)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="categoryForm.image"
                  height="100"
                  width="100"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectImg()"
              >
                Upload Image
              </b-button>
              <b-form-file
                ref="refImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewCoverEl"
                  v-img
                  rounded
                  :src="categoryForm.cover_image"
                  height="100"
                  width="100"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectCoverImg()"
              >
                Upload Cover Image
              </b-button>
              <b-form-file
                ref="coverImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeCoverImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addcategory()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addcategory()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {

  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      categories: [],
      countryId: '',
      loader: false,
      errorMsg: '',
      errors: {},
      editorOption: {

        placeholder: 'Enter Text',
        theme: 'snow',
      },
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const categoryForm = ref({
      image: null,
      cover_image: null,
    })
    // eslint-disable-next-line camelcase
    const image = ref('')
    const coverImage = ref('')

    return {
      getValidationState,
      categoryForm,
      image,
      coverImage,
    }
  },
  mounted() {
    this.showCategory()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    toggleAll(value, key) {
      if (this.allSelected[key]) {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.categoryForm.permission.push(item.id)
        })
      } else {
        // eslint-disable-next-line array-callback-return
        value.items.map(item => {
          this.categoryForm.permission.splice(this.categoryForm.permission.indexOf(item.id), 1)
        })
      }
    },

    showCategory() {
      if (this.$route.params.id) {
        axios.get(`gaming-categories/${this.$route.params.id}`).then(res => {
          this.categoryForm = res.data.data
        })
      } else {
        return false
      }
      return true
    },
    selectImg() {
      this.$refs.refImg.$el.click()
    },

    changeImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.categoryForm.image = er.target.result
          }
          // eslint-disable-next-line prefer-destructuring
          this.image = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    selectCoverImg() {
      this.$refs.coverImg.$el.click()
    },

    changeCoverImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.coverImage = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.categoryForm.cover_image = er.target.result
          }
          // eslint-disable-next-line prefer-destructuring
          this.coverImage = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    addcategory() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.categoryForm) {
          if (key !== 'image' || key !== 'cover_image') {
            formData.append(key, this.categoryForm[key])
          }
        }
        formData.delete('image')
        formData.delete('cover_image')

        if (this.coverImage) {
          formData.append('cover_image', this.coverImage)
        }
        if (this.image) {
          formData.append('image', this.image)
        }
        formData.append('_method', 'PUT')

        axios.post(`gaming-categories/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'gaming-categories' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.categoryForm) {
          if (key !== 'image' || key !== 'cover_image') {
            formData.append(key, this.categoryForm[key])
          }
        }
        formData.delete('image')
        formData.delete('cover_image')

        if (this.coverImage) {
          formData.append('cover_image', this.coverImage)
        }
        if (this.image) {
          formData.append('image', this.image)
        }

        this.loader = true
        axios.post('gaming-categories', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'gaming-categories' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                name: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },

}
</script>

  <style lang="scss">
  </style>
